import { PageProps } from "gatsby";
import Pricing from "../../components/Pricing";
import withPages from "../../hoc/withPages";

function IndexPage(props: PageProps) {
  return withPages(Pricing)({
    title: "เลือกแพลนสำหรับธุรกิจ",
    description:
      "deeple AI Chatbot รองรับการใช้งานตั้งแต่ธุรกิจขนาดเล็ก (SMEs) ไปจนถึงธุรกิจระดับ Enterprise สามารถเลือกแพลนการใช้งานที่เหมาะกับธุรกิจของคุณ",
    ...props,
  });
}

export default IndexPage;
