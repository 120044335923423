export const AllPlanFeature = {
  // Note:  detail = [Free  ,Basic  ,Standard ,Advanced ]
  chatAndShop: [
    {
      title: "AI ค้นหาสินค้าด้วยรูปภาพ",
      info: "Buy more via add-on",
      disabled: [true, false, false],
    },
    {
      title: "ระบบสอน AI ตอบคำถามเฉพาะธุรกิจ",
      info: "Train AI Chatbot",
      detail: ["30", "Up to 50 - 200", "custom"],
    },
    { title: "AI ช่วยจัดการที่อยู่อัตโนมัติ" },
    { title: "AI เสนอโปรโมชั่นอัตโนมัติ" },
    { title: "ระบบแสดงสินค้าเสมือนเว็บไซต์ (Web Shopping)" },
    {
      title: "โหมดการตอบแชท",
      detail: ["Admin, AI Sales", "Admin, AI Sales", "Admin, AI Sales"],
    },
    {
      title: "ระบบบรอดแคสต์บน Facebook และ LINE OA",
      detail: ["5,000", "Up to 50,000 - 500,000", "custom"],
    },
    {
      title: "ระบบตอบและดูดคอมเมนต์จาก Facebook Post",
      disabled: [false, false, false],
    },
    { title: "ระบบดูดคอมเมนต์จาก Facebook Live" },
    { title: "ระบบตอบและดูดคอมเมนต์จาก IG Post" },
    { title: "ระบบตอบและเสนอขายสินค้าจาก IG Story" },
    {
      title: "จำนวนลูกค้าสูงสุด",
      detail: ["5,000", "Up to 20,000 - 150,000", "custom"],
    },
    {
      title: "จำนวนออเดอร์ต่อเดือน",
      detail: ["Unlimited", "Unlimited", "Unlimited"],
    },
    {
      title: "ออเดอร์ออฟไลน์",
    },
    {
      title: "การส่งไฟล์ผ่านแชท",
      detail: [true, false, false],
    },
  ],
  storeManagement: [
    {
      title: "ระบบจัดการออเดอร์และดูแลสต็อก",
    },
    {
      title: "จำนวนรายการสินค้า",
      detail: ["500", "Up to 1,000 - 5,000", "custom"],
    },
    { title: "สินค้าดิจิทัล (เช่น คูปอง บัตรของขวัญ)" },
    { title: "การปรับแต่งธีมร้าน" },
    { title: "มัดจำและเก็บเงินปลายทาง (COD)" },
    { title: "รายงานการขาย" },
    { title: "ดาวน์โหลดรายงานการขาย", disabled: [true, false, false] },
    {
      title: "รายงานข้อมูลลูกค้า",
      disabled: [false, false, false],
    },
    {
      title: "ดาวน์โหลดรายงานข้อมูลลูกค้า",
      disabled: [true, false, false],
    },
    {
      title: "รายงานการจัดส่งสินค้า",
      disabled: [false, false, false],
    },
    {
      title: "ดาวน์โหลดรายงานการจัดส่งสินค้า",
      disabled: [true, false, false],
    },
    { title: "เครื่องมือสร้างแคมเปญและโปรโมชั่น" },
  ],
  shipping: [
    { title: "ตัวเลือกวิธีการจัดส่ง" },
    { title: "ตัวเลือกเวลาการจัดส่ง" },
    { title: "การคำนวณค่าจัดส่งคงที่" },
    { title: "การคำนวณค่าจัดส่งตามรหัสไปรษณีย์" },
    { title: "แอดมินคำนวณค่าจัดส่งด้วยตัวเอง" },
    { title: "รับสินค้าที่หน้าร้าน/สาขา" },
  ],
  integration: [
    {
      title: "แพลตฟอร์มแชท",
      detail: ["LINE,FB,IG", "LINE,FB,IG", "LINE,FB,IG"],
    },
    { title: "การเชื่อมต่อ API", disabled: [true, "custom", "custom"] },
    {
      title: "บริการขนส่งสินค้า",
      detail: ["Lalamove, SHIPPOP", "Lalamove, SHIPPOP", "Lalamove, SHIPPOP"],
    },
    {
      title: "ระบบชำระเงินออนไลน์อื่น ๆ",
      detail: [true, true, "2C2P, ChillPay"],
    },
  ],
  consoleAccessibility: [
    {
      title: "จำนวนแอดมิน",
      info: "Buy more via Add-on",
      detail: ["2", "5 - 15", "custom"],
    },
    { title: "แอป deeple Console (iOS & Android)" },
    {
      title: "ช่องทางติดต่อ deeple support",
      detail: ["Live Chat, Phone", "Live Chat, Phone", "Live Chat, Phone"],
    },
  ],
  AddOn: [
    {
      title: "ระบบสต็อก ZORT",
      mixValue: [true, "290", "290"],
    },
    {
      title: "AI ช่วยค้นหาสินค้าจากรูปภาพ",
      disabled: [true, false, false],
    },
  ],
  deeplePay: [
    {
      title: "บัตรเครดิต / เดบิต",
      detail: ["3.25%", "2.55%", "custom"],
      prefixDetail: ["", "Upto", ""],
      discount: ["", "3.25%", ""],
    },
    {
      title: "การชำระเงินด้วยคิวอาร์โค้ด",
      detail: ["1.25%", "0.55%", "custom"],
      prefixDetail: ["", "Upto", ""],
      discount: ["", "1.25%", ""],
    },
    {
      title: "โมบายแบงก์กิ้ง",
      detail: ["1.25%", "0.55%", "custom"],
      info: "Minimum THB15",
      prefixDetail: ["", "Upto", ""],
      discount: ["", "1.25%", ""],
    },
    {
      title: "แรบบิทไลน์เพย์",
      detail: ["3.55%", "2.75%", "custom"],
      prefixDetail: ["", "Upto", ""],
      discount: ["", "3.55%", ""],
    },
    {
      title: "ทรูมันนี่ วอลเล็ต",
      detail: ["2.95%", "2.65%", "custom"],
      prefixDetail: ["", "Upto", ""],
      discount: ["", "2.95%", ""],
    },
    {
      title: "ค่าธรรมเนียมการถอน-โอนเงิน (ต่อครั้ง)",
      detail: ["15", "15", "15"],
    },
    { title: "ถอน-โอนเงินแบบเรียลไทม์" },
  ],
  bankTransfer: [
    {
      title: "ค่าธรรมเนียมการโอน",
      detail: ["free", "free", "free"],
    },
    {
      title: "แอดมินอนุมัติการชำระเงิน",
      detail: ["50", "300 - 5,000", "10,000+"],
    },
  ],
};
